<script setup>
</script>

<template>
  <LayoutContainer class="mt-20 md:mt-3 text-main-gray">
    <h1>RexPromo</h1>
  </LayoutContainer>
</template>

<style lang="scss" scoped>
</style>